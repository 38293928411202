.button-dark,
.button-light {
  font-size: 1.25vw;
  display: flex;
  align-items: center;
  gap: 0.781vw;
  padding: 1.563vw 2.344vw;
  cursor: pointer;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  border-radius: 5.469vw;
  transition: 300ms background-color cubic-bezier(0.43, 0.195, 0.02, 1);
}
@media (max-width: 1150px) {
  .button-dark,
.button-light {
    font-size: 1.391vw;
    gap: 0.87vw;
    padding: 1.739vw 2.609vw;
    border-radius: 6.087vw;
  }
}
@media (max-width: 900px) {
  .button-dark,
.button-light {
    font-size: 14px;
    gap: 9px;
    padding: 18px 26px;
    border-radius: 60px;
  }
}
@media (max-width: 700px) {
  .button-dark,
.button-light {
    gap: 7px;
    padding: 15px 24px;
    border-radius: 54px;
  }
}
@media (max-width: 450px) {
  .button-dark,
.button-light {
    gap: 0px;
    padding: 18px 32px;
    border-radius: 54px;
  }
}
.button-dark svg,
.button-light svg {
  width: 1.172vw;
  transition: 300ms fill cubic-bezier(0.43, 0.195, 0.02, 1);
}
@media (max-width: 1150px) {
  .button-dark svg,
.button-light svg {
    width: 1.304vw;
  }
}
@media (max-width: 900px) {
  .button-dark svg,
.button-light svg {
    width: 13px;
  }
}
@media (max-width: 700px) {
  .button-dark svg,
.button-light svg {
    width: 12px;
  }
}
@media (max-width: 450px) {
  .button-dark svg,
.button-light svg {
    width: 0px;
  }
}

.button-light {
  color: #000;
  background-color: #fff;
}
.button-light .icon {
  fill: #000;
}
.button-light:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.12);
}
.button-light:hover .icon {
  fill: #fff;
}

.button-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.12);
}
.button-dark .icon {
  fill: #fff;
}
.button-dark:hover {
  color: #000;
  background-color: #fff;
}
.button-dark:hover .icon {
  fill: #000;
}

.button-scroll {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.12);
  aspect-ratio: auto 1/1;
  height: 100%;
  transition: 300ms background-color cubic-bezier(0.43, 0.195, 0.02, 1);
  cursor: pointer;
}
@media (max-width: 450px) {
  .button-scroll {
    display: none;
  }
}
.button-scroll .icon {
  width: 1.172vw;
  fill: #fff;
  transform: rotate(90deg);
  transition: 300ms fill cubic-bezier(0.43, 0.195, 0.02, 1);
}
@media (max-width: 1150px) {
  .button-scroll .icon {
    width: 1.304vw;
  }
}
@media (max-width: 900px) {
  .button-scroll .icon {
    width: 13px;
  }
}
@media (max-width: 700px) {
  .button-scroll .icon {
    width: 12px;
  }
}
.button-scroll:hover {
  color: #000;
  background-color: #fff;
}
.button-scroll:hover .icon {
  fill: #000;
}