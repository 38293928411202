.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 88vh;
  min-height: 350px;
  padding-top: 5.944vw;
}
@media (max-width: 1150px) {
  .hero {
    padding-top: 6.238vw;
  }
}
@media (max-width: 900px) {
  .hero {
    padding-top: 7.578vw;
  }
}
@media (max-width: 850px) {
  .hero {
    padding-top: 7.729vw;
  }
}
@media (max-width: 700px) {
  .hero {
    padding-top: 60.2px;
  }
}
@media (max-width: 600px) {
  .hero {
    padding-top: 53.2px;
  }
}
@media (max-width: 600px) {
  .hero {
    height: 70vh;
    min-height: 500px;
  }
}
.hero h1 div:nth-child(1) {
  transition-delay: 540ms !important;
}
.hero h1 div:nth-child(2) {
  transition-delay: 580ms !important;
}
.hero h1 div:nth-child(3) {
  transition-delay: 620ms !important;
}
.hero h1 div:nth-child(4) {
  transition-delay: 660ms !important;
}
.hero h1 div:nth-child(5) {
  transition-delay: 700ms !important;
}
.hero h1 div:nth-child(6) {
  transition-delay: 740ms !important;
}
.hero h1 div:nth-child(7) {
  transition-delay: 780ms !important;
}
.hero h1 div:nth-child(8) {
  transition-delay: 820ms !important;
}
.hero h1 div:nth-child(9) {
  transition-delay: 860ms !important;
}
.hero h1 div:nth-child(10) {
  transition-delay: 900ms !important;
}

.content-hero .hero {
  padding: 8.984vw 0;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  min-height: initial;
  margin-top: 5.944vw;
}
@media (max-width: 1150px) {
  .content-hero .hero {
    padding: 8.696vw 0;
  }
}
@media (max-width: 900px) {
  .content-hero .hero {
    padding: 9.778vw 0;
  }
}
@media (max-width: 700px) {
  .content-hero .hero {
    padding: 93px 0;
  }
}
@media (max-width: 600px) {
  .content-hero .hero {
    padding: 70px 0;
  }
}
@media (max-width: 450px) {
  .content-hero .hero {
    padding: 85px 0;
  }
}
@media (max-width: 375px) {
  .content-hero .hero {
    padding: 90px 0;
  }
}
@media (max-width: 1150px) {
  .content-hero .hero {
    margin-top: 6.238vw;
  }
}
@media (max-width: 900px) {
  .content-hero .hero {
    margin-top: 7.578vw;
  }
}
@media (max-width: 850px) {
  .content-hero .hero {
    margin-top: 7.729vw;
  }
}
@media (max-width: 700px) {
  .content-hero .hero {
    margin-top: 60.2px;
  }
}
@media (max-width: 600px) {
  .content-hero .hero {
    margin-top: 53.2px;
  }
}