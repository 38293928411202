nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  margin-top: 3.906vw;
}
@media (max-width: 1150px) {
  nav {
    margin-top: 4.348vw;
  }
}
@media (max-width: 900px) {
  nav {
    margin-top: 5vw;
  }
}
@media (max-width: 700px) {
  nav {
    margin-top: 37px;
  }
}
@media (max-width: 600px) {
  nav {
    margin-top: 30px;
  }
}
nav .logo {
  width: 14.44vw;
  display: flex;
  align-items: center;
}
@media (max-width: 1150px) {
  nav .logo {
    width: 11.478vw;
  }
}
@media (max-width: 900px) {
  nav .logo {
    width: 12.778vw;
  }
}
@media (max-width: 700px) {
  nav .logo {
    width: 102px;
  }
}
nav .logo img {
  width: 100%;
}
nav .button {
  display: flex;
  align-items: center;
}
nav .button p {
  margin-right: 4.688vw;
  cursor: pointer;
}
@media (max-width: 1150px) {
  nav .button p {
    margin-right: 5.391vw;
  }
}
@media (max-width: 900px) {
  nav .button p {
    margin-right: 5.889vw;
  }
}
@media (max-width: 700px) {
  nav .button p {
    margin-right: 50px;
  }
}
nav .button .hamburger-bar {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  right: 4.688vw;
  height: 50px;
  z-index: 999999;
}
@media (max-width: 1150px) {
  nav .button .hamburger-bar {
    right: 5.217vw;
  }
}
@media (max-width: 900px) {
  nav .button .hamburger-bar {
    right: 4.444vw;
  }
}
@media (max-width: 700px) {
  nav .button .hamburger-bar {
    right: 40px;
  }
}
@media (max-width: 450px) {
  nav .button .hamburger-bar {
    right: 30px;
  }
}
nav .button .hamburger-bar .line {
  background-color: #fff;
  width: 3.125vw;
  height: 1px;
  transition: 500ms cubic-bezier(0.19, 1, 0.22, 1) 83ms;
  position: relative;
  margin-left: auto;
}
@media (max-width: 1150px) {
  nav .button .hamburger-bar .line {
    width: 3.478vw;
  }
}
@media (max-width: 900px) {
  nav .button .hamburger-bar .line {
    width: 3.889vw;
  }
}
@media (max-width: 700px) {
  nav .button .hamburger-bar .line {
    width: 30px;
  }
}
nav .button .hamburger-bar .line:first-child {
  top: 0.508vw;
}
@media (max-width: 1150px) {
  nav .button .hamburger-bar .line:first-child {
    top: 0.565vw;
  }
}
@media (max-width: 900px) {
  nav .button .hamburger-bar .line:first-child {
    top: 0.6665vw;
  }
}
@media (max-width: 700px) {
  nav .button .hamburger-bar .line:first-child {
    top: 4.5px;
  }
}
nav .button .hamburger-bar .line:last-child {
  bottom: 0.508vw;
}
@media (max-width: 1150px) {
  nav .button .hamburger-bar .line:last-child {
    bottom: 0.565vw;
  }
}
@media (max-width: 900px) {
  nav .button .hamburger-bar .line:last-child {
    bottom: 0.6665vw;
  }
}
@media (max-width: 700px) {
  nav .button .hamburger-bar .line:last-child {
    bottom: 4.5px;
  }
}
nav .active-button .hamburger-bar .line {
  background-color: #000 !important;
}
nav .active-button .hamburger-bar .line:first-child {
  transform: rotate(-45deg);
  transform-origin: center;
  top: 0;
}
nav .active-button .hamburger-bar .line:last-child {
  transform: rotate(45deg);
  transform-origin: center;
  position: relative;
  bottom: 0;
}

nav.show-element {
  transition: 800ms opacity ease 333ms;
  opacity: 1;
}

.navigation-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  color: #000;
  width: 34.141vw;
  padding: 3.906vw 5.078vw 3.906vw 5.078vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  transition: opacity 830ms cubic-bezier(0.19, 1, 0.22, 1), transform 830ms cubic-bezier(0.19, 1, 0.22, 1);
  transform: translateY(20px);
  pointer-events: none;
  z-index: 10000;
}
@media (max-width: 1150px) {
  .navigation-menu {
    width: 37.13vw;
    padding: 3.913vw 5.217vw;
  }
}
@media (max-width: 900px) {
  .navigation-menu {
    width: 37.111vw;
    padding: 3.889vw 4.444vw;
  }
}
@media (max-width: 700px) {
  .navigation-menu {
    width: 334px;
    padding: 34px 40px;
  }
}
@media (max-width: 450px) {
  .navigation-menu {
    width: 100vw;
    padding: 30px;
    transform: translate(0px);
  }
}
.navigation-menu .top img {
  display: none;
}
@media (max-width: 450px) {
  .navigation-menu .top img {
    display: initial;
    width: 107px;
  }
}
.navigation-menu ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 1.563vw;
}
@media (max-width: 1150px) {
  .navigation-menu ul {
    gap: 1.913vw;
  }
}
@media (max-width: 900px) {
  .navigation-menu ul {
    gap: 2vw;
  }
}
@media (max-width: 700px) {
  .navigation-menu ul {
    gap: 20px;
  }
}
@media (max-width: 450px) {
  .navigation-menu ul {
    gap: 24px;
  }
}
.navigation-menu ul li {
  font-size: 3.125vw;
  line-height: 110%;
  transform: translateY(-2.344vw);
  font-weight: 300;
  opacity: 0;
  cursor: pointer;
}
@media (max-width: 1150px) {
  .navigation-menu ul li {
    font-size: 3.304vw;
  }
}
@media (max-width: 900px) {
  .navigation-menu ul li {
    font-size: 3.778vw;
  }
}
@media (max-width: 700px) {
  .navigation-menu ul li {
    font-size: 30px;
  }
}
@media (max-width: 450px) {
  .navigation-menu ul li {
    font-size: 42px;
  }
}
.navigation-menu ul li:nth-child(1) {
  transition: 670ms cubic-bezier(0.165, 0.84, 0.44, 1) 27ms;
}
.navigation-menu ul li:nth-child(2) {
  transition: 670ms cubic-bezier(0.165, 0.84, 0.44, 1) 77ms;
}
.navigation-menu ul li:nth-child(3) {
  transition: 670ms cubic-bezier(0.165, 0.84, 0.44, 1) 127ms;
}
.navigation-menu ul li:nth-child(4) {
  transition: 670ms cubic-bezier(0.165, 0.84, 0.44, 1) 177ms;
}
.navigation-menu ul li:nth-child(5) {
  transition: 670ms cubic-bezier(0.165, 0.84, 0.44, 1) 227ms;
}
.navigation-menu ul li:nth-child(6) {
  transition: 670ms cubic-bezier(0.165, 0.84, 0.44, 1) 277ms;
}
.navigation-menu ul li:nth-child(7) {
  transition: 670ms cubic-bezier(0.165, 0.84, 0.44, 1) 327ms;
}
.navigation-menu ul li:nth-child(8) {
  transition: 670ms cubic-bezier(0.165, 0.84, 0.44, 1) 377ms;
}
.navigation-menu .social-icons {
  display: flex;
  justify-content: space-between;
  transition: 670ms cubic-bezier(0.165, 0.84, 0.44, 1) 133ms;
  transform: translateY(2.344vw);
  opacity: 0;
}
@media (min-width: 1600px) {
  .navigation-menu .social-icons {
    opacity: 0 !important;
    pointer-events: none !important;
  }
}
.navigation-menu .social-icons svg {
  width: 3.594vw;
}
@media (max-width: 1150px) {
  .navigation-menu .social-icons svg {
    width: 4vw;
  }
}
@media (max-width: 900px) {
  .navigation-menu .social-icons svg {
    width: 3.778vw;
  }
}
@media (max-width: 700px) {
  .navigation-menu .social-icons svg {
    width: 35px;
  }
}
@media (max-width: 450px) {
  .navigation-menu .social-icons svg {
    width: 44px;
  }
}

.open {
  opacity: 1 !important;
  pointer-events: initial;
  transform: translateY(0px);
}
.open ul li,
.open .social-icons {
  transform: translateY(0%);
  opacity: 1;
}
