footer {
  padding: 8.594vw 0;
  overflow: hidden;
}
@media (max-width: 1150px) {
  footer {
    padding: 9.565vw 0;
  }
}
@media (max-width: 900px) {
  footer {
    padding: 12.333vw 0;
  }
}
@media (max-width: 700px) {
  footer {
    padding: 97px 0;
  }
}
@media (max-width: 600px) {
  footer {
    padding: 110px 0;
  }
}
footer .container-narrow {
  will-change: transform;
}
footer .container-narrow .top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 11.953vw;
}
@media (max-width: 1150px) {
  footer .container-narrow .top {
    margin-bottom: 14.174vw;
  }
}
@media (max-width: 900px) {
  footer .container-narrow .top {
    margin-bottom: 16.222vw;
  }
}
@media (max-width: 700px) {
  footer .container-narrow .top {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 30px;
    margin-bottom: 60px;
  }
}
@media (max-width: 450px) {
  footer .container-narrow .top {
    gap: 38px;
  }
  footer .container-narrow .top h2 {
    font-size: 40px;
  }
}
footer .container-narrow .button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  justify-self: flex-end;
}
footer .container-narrow .button-wrapper a {
  pointer-events: none;
}
footer .container-narrow .button-wrapper:hover a {
  color: #000;
  background-color: #fff;
}
footer .container-narrow .button-wrapper:hover a .icon {
  fill: #000;
}
footer .container-narrow .bottom {
  display: flex;
  flex-direction: column;
  gap: 2.344vw;
}
@media (max-width: 1150px) {
  footer .container-narrow .bottom {
    gap: 2.696vw;
  }
}
@media (max-width: 900px) {
  footer .container-narrow .bottom {
    gap: 2.889vw;
  }
}
@media (max-width: 700px) {
  footer .container-narrow .bottom {
    gap: 60px;
  }
}
footer .container-narrow .bottom .line {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 700px) {
  footer .container-narrow .bottom .line {
    flex-direction: column;
  }
  footer .container-narrow .bottom .line .back {
    display: none;
  }
}
footer .container-narrow .bottom .line .contact-info {
  display: flex;
  gap: 3.906vw;
}
@media (max-width: 1150px) {
  footer .container-narrow .bottom .line .contact-info {
    gap: 4.522vw;
  }
}
@media (max-width: 900px) {
  footer .container-narrow .bottom .line .contact-info {
    gap: 4.889vw;
  }
}
@media (max-width: 700px) {
  footer .container-narrow .bottom .line .contact-info {
    flex-direction: column;
    margin: auto;
    gap: 25px;
  }
  footer .container-narrow .bottom .line .contact-info a {
    text-align: center;
    margin: auto;
  }
}
footer .container-narrow .bottom .line .contact-info a {
  text-decoration: none;
  color: #000;
}
footer .container-narrow .bottom .line .copyrights {
  opacity: 0.37;
}
@media (max-width: 700px) {
  footer .container-narrow .bottom .line .copyrights {
    order: 1;
    text-align: center;
    margin-top: 30px;
  }
}
footer .container-narrow .bottom .line .social-icons {
  display: flex;
  justify-content: space-between;
  width: 23.906vw;
}
@media (max-width: 1150px) {
  footer .container-narrow .bottom .line .social-icons {
    width: 26.609vw;
  }
}
@media (max-width: 900px) {
  footer .container-narrow .bottom .line .social-icons {
    width: 29.556vw;
  }
}
@media (max-width: 700px) {
  footer .container-narrow .bottom .line .social-icons {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    gap: 20px;
    margin: auto;
  }
}
@media (max-width: 375px) {
  footer .container-narrow .bottom .line .social-icons {
    gap: 15px;
  }
}
@media (max-width: 320px) {
  footer .container-narrow .bottom .line .social-icons {
    gap: 10px;
  }
}
footer .container-narrow .bottom .line .social-icons svg {
  fill: #000;
  width: 3.594vw;
}
@media (max-width: 1150px) {
  footer .container-narrow .bottom .line .social-icons svg {
    width: 4vw;
  }
}
@media (max-width: 900px) {
  footer .container-narrow .bottom .line .social-icons svg {
    width: 4.444vw;
  }
}
@media (max-width: 700px) {
  footer .container-narrow .bottom .line .social-icons svg {
    width: 50px;
  }
}
@media (max-width: 450px) {
  footer .container-narrow .bottom .line .social-icons svg {
    width: 40px;
  }
}